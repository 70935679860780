
import { computed, defineComponent, ref } from "vue";
import { routeNames } from "@/router";
import { lessonDigitalBookPageColumns } from "@/columns";
import {
    LessonDigitalBookPageGridModel,
    LessonDigitalBookPageModel
} from "@/models";
import ApiGrid from "./ApiGrid.vue";
import { DialogVue3 } from "@progress/kendo-vue-dialogs/dist/npm/Dialog";
import LessonDigitalBookPageForm from "./LessonDigitalBookPageForm.vue";
import LoadingIndicator from "./LoadingIndicator.vue";
import { restClient } from "@/store/actions";
import { createUniqueUrl } from "@/helpers";

export default defineComponent({
    components: {
        "api-grid": ApiGrid,
        "k-dialog": DialogVue3,
        "lesson-digital-book-page-form": LessonDigitalBookPageForm,
        "loading-indicator": LoadingIndicator
    },
    props: {
        courseId: {
            type: Number,
            required: true
        },
        lessonId: {
            type: Number,
            required: true
        }
    },
    setup(props) {
        const gridId = computed(
            () => `lesson-digital-book-page-grid-${props.lessonId}`
        );

        const requestBody = ref<LessonDigitalBookPageGridModel>({
            lessonId: props.lessonId
        });

        const bookPage = ref<LessonDigitalBookPageModel | undefined>();

        const visibleDialog = ref(false);
        const toggleDialog = (): void => {
            visibleDialog.value = !visibleDialog.value;
        };
        const modalTitle = computed(() =>
            bookPage.value?.id && bookPage.value.id > 0
                ? "Edit Digital Book Page"
                : "Add Digital Book Page"
        );

        const handleCreate = (): void => {
            bookPage.value = undefined;

            toggleDialog();

            bookPage.value = {
                id: 0,
                sortOrder: null,
                lessonId: props.lessonId,
                imageUrl: " "
            };
        };

        const handleEdit = async (id: number): Promise<void> => {
            bookPage.value = undefined;

            toggleDialog();

            const response =
                await restClient.getJson<LessonDigitalBookPageModel>(
                    `/api/admin/LessonDigitalBookPage/${id}`
                );

            if (response.status !== 200) {
                toggleDialog();
            } else {
                bookPage.value = response.data;
            }
        };

        return {
            routeNames,
            lessonDigitalBookPageColumns,
            requestBody,
            visibleDialog,
            toggleDialog,
            bookPage,
            handleCreate,
            handleEdit,
            createUniqueUrl,
            modalTitle,
            gridId
        };
    }
});
