
import { computed, defineComponent, PropType, reactive, ref } from "vue";
import { Form, FormActions } from "vee-validate";
import { getModelStateFromResponse, hasErrors } from "@elite/validation";
import { LessonFileUploadModel } from "@/models";
import { useStore, AppGetterTypes } from "@/store";
import {
    createImageFormData,
    formatDate,
    promptDelete,
    pdfUploadFileRestrictions,
    createUniqueUrl,
    PDF_TOOLTIP_MESSAGE
} from "@/helpers";
import { AppActionTypes, restClient } from "@/store/actions";
import { ValFormInput } from "@elite/validation";
import {
    UploadFileInfo,
    UploadOnAddEvent,
    UploadOnRemoveEvent,
    UploadVue3
} from "@progress/kendo-vue-upload";

import InformationTooltipVue from "./InformationTooltip.vue";

export default defineComponent({
    props: {
        fileUpload: {
            type: Object as PropType<LessonFileUploadModel>,
            required: true
        },
        courseId: {
            type: Number,
            required: true
        },
        lessonId: {
            type: Number,
            required: true
        },

        /** ID for a grid to refresh after an add/edit is made */
        refreshGridId: {
            type: String,
            required: false,
            default: ""
        }
    },
    components: {
        "v-form": Form,
        "val-form-input": ValFormInput,
        "upload": UploadVue3,
        "information-tooltip": InformationTooltipVue
    },
    emits: ["handleClose"],
    setup(props, { emit }) {
        const store = useStore();

        const model: LessonFileUploadModel = reactive(props.fileUpload);
        const rules = computed(
            () =>
                store.getters[AppGetterTypes.validationRules]
                    ?.lessonFileUploadModel
        );
        const fileUploadTypes = computed(
            () => store.getters[AppGetterTypes.fileUploadTypes]
        );

        const inserted = computed(() =>
            model.insertedOn ? formatDate(model.insertedOn) : "-"
        );
        const updated = computed(() =>
            model.updatedOn ? formatDate(model.updatedOn) : "-"
        );
        const isNew = model.id === 0;

        // Image upload
        const imageFile = ref<UploadFileInfo>();
        const previewSource = ref<string>();
        const imageHasChanged = ref(false);

        // Submit data
        const onSubmit = async (
            values: LessonFileUploadModel,
            actions: FormActions<Record<string, unknown>>
        ): Promise<void> => {
            // Trigger validation for new course on submit if no image is selected
            if (isNew && !imageFile.value) {
                imageHasChanged.value = true;
            }

            // If the image has changed, ensure it is valid and present
            if (
                (!imageFile.value && imageHasChanged.value) ||
                imageFile.value?.validationErrors
            ) {
                return;
            }

            // Save course data
            const response = isNew
                ? await restClient.postJson<LessonFileUploadModel>(
                      "/api/admin/LessonFileUpload",
                      model
                  )
                : await restClient.putJson<LessonFileUploadModel>(
                      "/api/admin/LessonFileUpload",
                      model
                  );

            if (hasErrors(response)) {
                getModelStateFromResponse(response, actions);
                return;
            }

            // If the image has changed and there are no errors, upload the new one
            if (imageHasChanged.value && imageFile.value) {
                const formData = createImageFormData(imageFile.value);

                // Save image
                const imageUploadResponse = await restClient.postJson(
                    `/api/admin/LessonFileUploadFile?modelId=${response.data?.id}`,
                    formData
                );

                if (hasErrors(imageUploadResponse)) {
                    getModelStateFromResponse(response, actions);
                    return;
                }
            }

            // Refresh a grid on the outside of this component after an add/edit happened
            if (props.refreshGridId) {
                await store.dispatch(
                    AppActionTypes.refreshGrid,
                    props.refreshGridId
                );
            }

            emit("handleClose");
        };

        // Prompt user to delete file upload
        const handleDelete = async (): Promise<void> => {
            if (
                await promptDelete("Are you sure you want to delete the file?")
            ) {
                const response = await restClient.delete(
                    `/api/admin/LessonFileUpload/${model.id}`
                );

                if (hasErrors(response)) {
                    return;
                }

                emit("handleClose");
            }
        };

        // Emit cancel event to close modal
        const handleCancel = (): void => {
            emit("handleClose");
        };

        // Add image to upload component and build preview
        const addImage = (event: UploadOnAddEvent): void => {
            const newImage = event.newState[0];
            imageFile.value = newImage;
            imageHasChanged.value = true;

            // Manual image previews without upload, adapted from Kendo documentation
            // See: https://docs.telerik.com/kendo-ui/controls/editors/upload/how-to/add-image-preview
            if (
                newImage.validationErrors === undefined &&
                newImage.getRawFile !== undefined
            ) {
                const reader = new FileReader();
                const raw = newImage.getRawFile();

                if (raw) {
                    reader.onload = function (): void {
                        previewSource.value = this.result as string;
                    };

                    reader.readAsDataURL(raw);
                }
            }
        };

        // Remove new image from upload component
        const removeImage = async (
            event: UploadOnRemoveEvent
        ): Promise<void> => {
            if (
                await promptDelete("Are you sure you want to delete this file?")
            ) {
                imageFile.value = event.newState[0];
                previewSource.value = undefined;
            }
        };

        return {
            onSubmit,
            model,
            rules,
            inserted,
            updated,
            isNew,
            handleDelete,
            fileUploadTypes,
            handleCancel,
            addImage,
            removeImage,
            imageFile,
            previewSource,
            imageHasChanged,
            pdfUploadFileRestrictions,
            createUniqueUrl,
            PDF_TOOLTIP_MESSAGE
        };
    }
});
