
import { computed, defineComponent, ref } from "vue";
import { routeNames } from "@/router";
import { LessonFileUploadGridModel, LessonFileUploadModel } from "@/models";
import ApiGrid from "./ApiGrid.vue";
import { restClient } from "@/store/actions";
import { lessonFileUploadColumns } from "@/columns";
import LessonFileUploadForm from "./LessonFileUploadForm.vue";
import LoadingIndicator from "./LoadingIndicator.vue";
import { DialogVue3 } from "@progress/kendo-vue-dialogs/dist/npm/Dialog";
import { createUniqueUrl } from "@/helpers";

export default defineComponent({
    components: {
        "api-grid": ApiGrid,
        "k-dialog": DialogVue3,
        "lesson-file-upload-form": LessonFileUploadForm,
        "loading-indicator": LoadingIndicator
    },
    props: {
        courseId: {
            type: Number,
            required: true
        },
        lessonId: {
            type: Number,
            required: true
        }
    },
    setup(props) {
        const gridId = computed(
            () => `lesson-file-upload-grid-${props.lessonId}`
        );

        const requestBody = ref<LessonFileUploadGridModel>({
            lessonId: props.lessonId
        });

        const fileUpload = ref<LessonFileUploadModel | undefined>();

        const visibleDialog = ref(false);
        const toggleDialog = (): void => {
            visibleDialog.value = !visibleDialog.value;
        };
        const modalTitle = computed(() =>
            fileUpload.value?.id && fileUpload.value.id > 0
                ? "Edit File"
                : "Add File"
        );

        const handleCreate = (): void => {
            fileUpload.value = undefined;

            toggleDialog();

            fileUpload.value = {
                id: 0,
                lessonId: props.lessonId,
                fileUploadTypeId: undefined,
                fileUrl: " "
            };
        };

        const handleEdit = async (id: number): Promise<void> => {
            fileUpload.value = undefined;

            toggleDialog();

            const response = await restClient.getJson<LessonFileUploadModel>(
                `/api/admin/LessonFileUpload/${id}`
            );

            if (response.status !== 200) {
                toggleDialog();
            }

            fileUpload.value = response.data;
        };

        return {
            routeNames,
            lessonFileUploadColumns,
            requestBody,
            fileUpload,
            handleCreate,
            handleEdit,
            visibleDialog,
            toggleDialog,
            modalTitle,
            createUniqueUrl,
            gridId
        };
    }
});
