
import {
    computed,
    defineComponent,
    onMounted,
    PropType,
    reactive,
    ref
} from "vue";
import { Form, FormActions } from "vee-validate";
import {
    getModelStateFromResponse,
    hasErrors,
    ValFormInput
} from "@elite/validation";
import {
    UploadFileInfo,
    UploadOnAddEvent,
    UploadOnRemoveEvent,
    UploadVue3
} from "@progress/kendo-vue-upload";
import { LessonModel } from "@/models";
import { useStore, AppGetterTypes } from "@/store";
import { useRouter } from "vue-router";
import {
    createImageFormData,
    formatDate,
    promptDelete,
    jpgUploadFileRestrictions,
    pngUploadFileRestrictions,
    createUniqueUrl,
    JPG_TOOLTIP_MESSAGE,
    PNG_TOOLTIP_MESSAGE
} from "@/helpers";
import { routeNames, routePaths } from "@/router";
import { restClient } from "@/store/actions";

import UploadPreview from "./UploadPreview.vue";
import InformationTooltipVue from "./InformationTooltip.vue";

export default defineComponent({
    props: {
        lesson: {
            type: Object as PropType<LessonModel>,
            required: true
        },
        courseId: {
            type: Number,
            required: true
        }
    },
    components: {
        "v-form": Form,
        "val-form-input": ValFormInput,
        "upload": UploadVue3,
        "upload-preview": UploadPreview,
        "information-tooltip": InformationTooltipVue
    },
    setup(props) {
        const router = useRouter();
        const store = useStore();

        const model: LessonModel = reactive(props.lesson);
        const rules = computed(
            () => store.getters[AppGetterTypes.validationRules]?.lessonModel
        );

        const isNew = model.id === 0;
        const inserted = computed(() =>
            model.insertedOn ? formatDate(model.insertedOn) : "-"
        );
        const updated = computed(() =>
            model.updatedOn ? formatDate(model.updatedOn) : "-"
        );
        const lessonCoursePath = {
            name: routeNames.editCourse,
            params: { courseId: props.courseId }
        };

        // Thumbnail Image upload
        const thumbnailImageFile = ref<UploadFileInfo>();
        const thumbnailPreviewSource = ref<string>();
        const thumbnailImageHasChanged = ref(false);

        // Sticker Image upload
        const stickerImageFile = ref<UploadFileInfo>();
        const stickerPreviewSource = ref<string>();
        const stickerImageHasChanged = ref(false);

        onMounted(() => {
            // If editing, set the preview thumbnail image with a timestamp to cache bust
            if (!isNew && model?.thumbnailImageUrl) {
                thumbnailPreviewSource.value = createUniqueUrl(
                    model.thumbnailImageUrl
                );
            }
            // If editing, set the preview sticker image with a timestamp to cache bust
            if (!isNew && model?.stickerImageUrl) {
                stickerPreviewSource.value = createUniqueUrl(
                    model.stickerImageUrl
                );
            }
        });

        // Create/update lesson
        const onSubmit = async (
            values: LessonModel,
            actions: FormActions<Record<string, unknown>>
        ): Promise<void> => {
            // Trigger validation for new course on submit if no image is selected
            if (isNew && !thumbnailImageFile.value) {
                thumbnailImageHasChanged.value = true;
            }

            // Trigger validation for new course on submit if no image is selected
            if (isNew && !stickerImageFile.value) {
                stickerImageHasChanged.value = true;
            }

            // If the image has changed, ensure it is valid and present
            if (
                (!thumbnailImageFile.value && thumbnailImageHasChanged.value) ||
                thumbnailImageFile.value?.validationErrors
            ) {
                return;
            }

            // If the image has changed, ensure it is valid and present
            if (
                (!stickerImageFile.value && stickerImageHasChanged.value) ||
                stickerImageFile.value?.validationErrors
            ) {
                return;
            }

            // Set sort order to null if it has been removed
            if (!model.sortOrder) {
                model.sortOrder = null;
            }

            // Save lesson data
            const response = isNew
                ? await restClient.postJson<LessonModel>(
                      "/api/admin/Lesson",
                      model
                  )
                : await restClient.putJson<LessonModel>(
                      "/api/admin/Lesson",
                      model
                  );

            if (hasErrors(response)) {
                getModelStateFromResponse(response, actions);
                return;
            }

            // If the thumbnail image has changed and there are no errors, upload the new one
            if (thumbnailImageHasChanged.value && thumbnailImageFile.value) {
                const formData = createImageFormData(thumbnailImageFile.value);

                // Save image
                const thumbnailImageUploadResponse = await restClient.postJson(
                    `/api/admin/LessonImage/thumbnail?modelId=${response.data?.id}`,
                    formData
                );

                if (hasErrors(thumbnailImageUploadResponse)) {
                    getModelStateFromResponse(response, actions);
                    return;
                }
            }
            // If the sticker image has changed and there are no errors, upload the new one
            if (stickerImageHasChanged.value && stickerImageFile.value) {
                const formData = createImageFormData(stickerImageFile.value);

                // Save image
                const stickerImageUploadResponse = await restClient.postJson(
                    `/api/admin/LessonImage/sticker?modelId=${response.data?.id}`,
                    formData
                );

                if (hasErrors(stickerImageUploadResponse)) {
                    getModelStateFromResponse(response, actions);
                    return;
                }
            }

            router.push(lessonCoursePath);
        };

        // Prompt user to delete lesson
        const handleDelete = async (): Promise<void> => {
            if (
                await promptDelete(
                    "Are you sure you want to delete the lesson?"
                )
            ) {
                const response = await restClient.delete(
                    `/api/admin/Lesson/${model.id}`
                );

                if (hasErrors(response)) {
                    return;
                }

                router.push(lessonCoursePath);
            }
        };

        // Add thumbnail image to upload component and build preview
        const addThumbnailImage = (event: UploadOnAddEvent): void => {
            const newImage = event.newState[0];
            thumbnailImageFile.value = newImage;
            thumbnailImageHasChanged.value = true;

            // Manual image previews without upload, adapted from Kendo documentation
            // See: https://docs.telerik.com/kendo-ui/controls/editors/upload/how-to/add-image-preview
            if (
                newImage.validationErrors === undefined &&
                newImage.getRawFile !== undefined
            ) {
                const reader = new FileReader();
                const raw = newImage.getRawFile();

                if (raw) {
                    reader.onload = function (): void {
                        thumbnailPreviewSource.value = this.result as string;
                    };

                    reader.readAsDataURL(raw);
                }
            }
        };

        // Remove thumbnail new image from upload component
        const removeThumbnailImage = async (
            event: UploadOnRemoveEvent
        ): Promise<void> => {
            if (
                await promptDelete(
                    "Are you sure you want to delete this image?"
                )
            ) {
                thumbnailImageFile.value = event.newState[0];
                thumbnailPreviewSource.value = undefined;
            }
        };
        // Add sticker image to upload component and build preview
        const addStickerImage = (event: UploadOnAddEvent): void => {
            const newImage = event.newState[0];
            stickerImageFile.value = newImage;
            stickerImageHasChanged.value = true;

            // Manual image previews without upload, adapted from Kendo documentation
            // See: https://docs.telerik.com/kendo-ui/controls/editors/upload/how-to/add-image-preview
            if (
                newImage.validationErrors === undefined &&
                newImage.getRawFile !== undefined
            ) {
                const reader = new FileReader();
                const raw = newImage.getRawFile();

                if (raw) {
                    reader.onload = function (): void {
                        stickerPreviewSource.value = this.result as string;
                    };

                    reader.readAsDataURL(raw);
                }
            }
        };

        // Remove new sticker image from upload component
        const removeStickerImage = async (
            event: UploadOnRemoveEvent
        ): Promise<void> => {
            if (
                await promptDelete(
                    "Are you sure you want to delete this image?"
                )
            ) {
                stickerImageFile.value = event.newState[0];
                stickerPreviewSource.value = undefined;
            }
        };

        return {
            onSubmit,
            model,
            rules,
            isNew,
            inserted,
            updated,
            routePaths,
            handleDelete,
            lessonCoursePath,
            thumbnailImageFile,
            thumbnailPreviewSource,
            thumbnailImageHasChanged,
            stickerImageFile,
            stickerPreviewSource,
            stickerImageHasChanged,
            jpgUploadFileRestrictions,
            pngUploadFileRestrictions,
            addThumbnailImage,
            removeThumbnailImage,
            addStickerImage,
            removeStickerImage,
            JPG_TOOLTIP_MESSAGE,
            PNG_TOOLTIP_MESSAGE
        };
    }
});
