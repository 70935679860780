import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-xl-6 offset-xl-3" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "text-center" }
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lesson_form = _resolveComponent("lesson-form")!
  const _component_loading_indicator = _resolveComponent("loading-indicator")!
  const _component_content_card = _resolveComponent("content-card")!
  const _component_quiz_grid = _resolveComponent("quiz-grid")!
  const _component_lesson_digital_book_page_grid = _resolveComponent("lesson-digital-book-page-grid")!
  const _component_lesson_file_upload_grid = _resolveComponent("lesson-file-upload-grid")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_content_card, null, {
          body: _withCtx(() => [
            (_ctx.model?.id !== undefined)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.model.id > 0
                                        ? _ctx.model.name || `Lesson ${_ctx.model.id}`
                                        : "New Lesson"), 1),
                  _createVNode(_component_lesson_form, {
                    "course-id": _ctx.courseId,
                    lesson: _ctx.model
                  }, null, 8, ["course-id", "lesson"])
                ]))
              : (_openBlock(), _createBlock(_component_loading_indicator, { key: 1 }))
          ]),
          _: 1
        })
      ])
    ]),
    (_ctx.model?.id && _ctx.model?.id > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_content_card, { class: "mt-3" }, {
            body: _withCtx(() => [
              _createVNode(_component_quiz_grid, {
                "course-id": _ctx.courseId,
                "lesson-id": _ctx.lessonId
              }, null, 8, ["course-id", "lesson-id"])
            ]),
            _: 1
          }),
          _createVNode(_component_content_card, { class: "mt-5" }, {
            body: _withCtx(() => [
              _createVNode(_component_lesson_digital_book_page_grid, {
                "course-id": _ctx.courseId,
                "lesson-id": _ctx.lessonId
              }, null, 8, ["course-id", "lesson-id"])
            ]),
            _: 1
          }),
          _createVNode(_component_content_card, { class: "mt-5" }, {
            body: _withCtx(() => [
              _createVNode(_component_lesson_file_upload_grid, {
                "course-id": _ctx.courseId,
                "lesson-id": _ctx.lessonId
              }, null, 8, ["course-id", "lesson-id"])
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}