
import { defineComponent, onMounted, ref } from "vue";
import ContentCard from "@/components/ContentCard.vue";
import LessonForm from "@/components/LessonForm.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import QuizGrid from "@/components/QuizGrid.vue";
import LessonDigitalBookPageGrid from "@/components/LessonDigitalBookPageGrid.vue";
import LessonFileUploadGrid from "@/components/LessonFileUploadGrid.vue";
import { LessonModel, QuizGridModel } from "@/models";
import { restClient } from "@/store/actions";
import { useRouter } from "vue-router";
import { routePaths } from "@/router";

export default defineComponent({
    components: {
        "content-card": ContentCard,
        "lesson-form": LessonForm,
        "loading-indicator": LoadingIndicator,
        "quiz-grid": QuizGrid,
        "lesson-digital-book-page-grid": LessonDigitalBookPageGrid,
        "lesson-file-upload-grid": LessonFileUploadGrid
    },
    props: {
        courseId: { type: Number, required: true },
        lessonId: { type: Number, required: true }
    },
    setup(props) {
        const router = useRouter();
        const model = ref<LessonModel>({});
        const requestFilter = ref<QuizGridModel>({});

        if (!props.courseId) {
            router.push(routePaths.courseManagementPath);
        }

        onMounted(async () => {
            if (props.lessonId === 0) {
                model.value = {
                    id: 0,
                    name: null,
                    summary: null,
                    sortOrder: null,
                    thumbnailImageUrl: " ",
                    stickerImageUrl: " ",
                    itemNumber: null,
                    videoId: null,
                    videoTitle: null,
                    courseId: props.courseId
                };
            } else {
                const response = await restClient.getJson<LessonModel>(
                    `/api/admin/Lesson/${props.lessonId}`
                );

                if (response.data) {
                    model.value = response.data;
                    requestFilter.value = {
                        lessonId: model.value.id
                    };
                }
            }
        });

        return {
            model
        };
    }
});
