
import { computed, defineComponent, ref } from "vue";
import { routeNames } from "@/router";
import { quizColumns } from "@/columns";
import { QuizGridModel } from "@/models";
import ApiGrid from "./ApiGrid.vue";

export default defineComponent({
    components: {
        "api-grid": ApiGrid
    },
    props: {
        courseId: {
            type: Number,
            required: true
        },
        lessonId: {
            type: Number,
            required: true
        }
    },
    setup(props) {
        const gridId = computed(() => `quiz-grid-${props.lessonId}`);

        const requestBody = ref<QuizGridModel>({
            lessonId: props.lessonId
        });

        return {
            routeNames,
            quizColumns,
            requestBody,
            gridId
        };
    }
});
